<template>
  <v-card>
    <v-card-title class="headline"
      >Confirmar acción
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      ¿Está seguro que desea eliminar
      <span class="font-weight-bold">{{ item.name }}</span
      >?
    </v-card-text>

    <v-card-actions class="pr-5 pl-5" style="justify-content: center">
      <v-btn
        color="red darken-4"
        style="border-radius: 50px"
        height="45"
        block
        class="mb-3"
        dark
        :loading="loading"
        @click="$emit('confirm')"
      >
        Eliminar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["item", "loading"],
};
</script>

<style scoped>
</style>