<template>
  <v-card>
    <v-card-title class="headline">
      {{ selectedItem ? "Editar" : "Nuevo" }} monto permitido
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        :loading="loading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-snackbar top :timeout="3000" v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>
      <v-row class="mt-5">
        <v-col cols="12" sm="12" md="12">
          <h2>Nombre del monto</h2>
          <v-text-field
            rounded
            outlined
            :loading="loading"
            placeholder="Ingrese el nombre del monto"
            v-model="item.name"
          />
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <h2>Monto</h2>
          <v-text-field
            rounded
            outlined
            :loading="loading"
            @keypress="isNumber($event)"
            placeholder="Ingrese el monto"
            v-model.number="item.amount"
          />
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="6" md="6">
          <v-switch label="Activo" v-model="item.active"></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-row justify="end">
            <v-btn
              class="save-btn"
              :loading="loading"
              @click="save"
              color="primary"
              dark
              >Guardar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { db } from "@/firebase";

export default {
  name: "add-amount",
  props: ["businessId", "selectedItem"],
  data() {
    return {
      item: {
        name: "",
        active: true,
        deleted: false,
      },
      loading: true,
      snackbarText: "",
      snackbar: false,
    };
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    save() {
      if (this.item.name && this.item.amount) {
        this.loading = true;

        let data = {
          name: this.item.name,
          active: this.item.active ? this.item.active : false,
          amount: Number(this.item.amount),

          deleted: false,
        };

        let query = db
          .collection("corporateCoupons")
          .doc(this.businessId)
          .collection("amounts");

        if (this.selectedItem) {
          data.modifiedAt = new Date();
          data.modifiedBy = this.$store.state.user[".key"];
          query = query.doc(this.selectedItem[".key"]).update(data);
        } else {
          data.createdAt = new Date();
          data.createdBy = this.$store.state.user[".key"];
          query = query.add(data);
        }

        query
          .then(async (ref) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Ingrese el nombre y el monto.";
        this.snackbar = true;
      }
    },
  },
  mounted() {
    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
    }

    this.loading = false;
  },
};
</script>